<template>
  <!-- 报警记录页面 -->
  <div>
    <!-- 头部开始 -->
    <baidu-map class="bm-view"></baidu-map>
    <el-row class="pur-top">
      <el-col :span="3">
        <span class="pur-size">{{ purTitle }}</span></el-col>
        <el-col :span="21" class="pur-right">
            <el-input
            @keyup.enter.native="search"
            size="small"
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            v-model="purSearch">
            </el-input>
        </el-col>
    </el-row>
    <!-- 头部结束 -->
    <!-- 内容开始 -->
    <div class="pur-nav"
    v-loading="loading"
    element-loading-text = "加载中...">
      <!-- 表格 -->
      <div class="pur-table">
        <el-table
          height="100%"
          border
          :data="tableData"
          :header-cell-style="{ background: '#F6F6F6', color: '#333' }"
          style="width: 100%;">
            <el-table-column prop="id" v-if="show">
            </el-table-column>
            <el-table-column label="路线名称" :show-overflow-tooltip="true" prop="fenceName">
            </el-table-column>
            <el-table-column label="车牌号" :show-overflow-tooltip="true" prop="licensePlate">
            </el-table-column>
            <el-table-column label="姓名" :show-overflow-tooltip="true" prop="driverName">
            </el-table-column>
            <el-table-column label="联系方式" :show-overflow-tooltip="true" prop="phone">
            </el-table-column>
            <el-table-column label="报警时间" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.alocTime">{{scope.row.alocTime|TimeFliter}}</span>
              </template>
            </el-table-column>
            <el-table-column label="报警类型" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                <span v-if="scope.row.alarmType == 1">偏移报警</span>
                <span v-if="scope.row.alarmType == 2">超时停车报警</span>
                <span v-if="scope.row.alarmType == 3">超速报警</span>
                <span v-if="scope.row.alarmType == 4">超时行驶报警</span>
              </template>
            </el-table-column>
            <el-table-column
              label="报警信息"
              width="100" fixed="right">
              <template slot-scope="scope">
                <span @click="onePurs(scope.row)" style="color:rgba(58, 111, 195, 1);cursor: pointer;">查看</span>
              </template>
            </el-table-column>
          <!-- 暂无数据 -->
          <div slot="empty" class="dataAvailable">
              <div class="dataAva">
                <img src="../assets/zanwushuju.png" alt="">
                <p>暂无报警记录！</p>
              </div>
          </div>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pur-pages">
        <el-pagination
        background
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size.sync="size"
        :pager-count="5"
        :current-page.sync="current"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @current-change='getpurcon'
        @size-change='getpurcon'>
        </el-pagination>
      </div>
    </div>
    <!-- 报警信息弹窗 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :closeOnClickModal="false"
      width="50%"
      :title="oneData.licensePlate + '-报警信息'"
      :before-close="handleClose" v-if="showmap">
        <div class="bevconentBox">
          <baidu-map class="bmviewbox" MapType="BMAP_SATELLITE_MAP" :center="centermap" :zoom="zoom" :scroll-wheel-zoom="true" :continuous-zoom="true">
              <!--   -->
              <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
              <!-- 地图缩放控件 -->
              <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" :offset="{width:10,height:10}"></bm-navigation>
              <!-- 地图类型 -->
              <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_BOTTOM_LEFT" :offset="{width:100,height:20}"></bm-map-type>
              <!-- 起始点路线规划控件 -->
              <!-- 折线地图 -->
               <bm-polyline v-for="(v,k) in newpath" :key="k" :path="v.path" :stroke-color="v.rgb" :stroke-opacity="0.6" :stroke-weight="8" :editing="false"></bm-polyline>
              <!-- 点控件 -->
              <div v-if="oneData.alarmType == 4">
                <bm-marker v-for="item in policeDas" :key="'k' + item.id" :position="{lng:item.lng,lat:item.lat}" :dragging="false" :icon="{url: require('@/assets/jgtp.png'), size: {width:48, height:48}}">
                </bm-marker>
              </div>
              <div v-else>
                <bm-marker :position="markerPoint" :dragging="false" :icon="{url: require('@/assets/jgtp.png'), size: {width:48, height:48}}">
                </bm-marker>
              </div>

          </baidu-map>
          <!-- <div class="bevconentbottom" @click="dwmap">
            <i class="el-icon-map-location"></i>
            <p>定 位</p>
          </div> -->
        </div>
        <el-descriptions :column="1" v-if="oneData.alarmType == 4">
            <el-descriptions-item label="离开起点时间">{{oneData.leaveTime}}</el-descriptions-item>
            <el-descriptions-item label="到达终点时间">{{oneData.enterTime}}</el-descriptions-item>
            <el-descriptions-item label="车辆行驶时间">{{oneData.travelTime}} 分钟</el-descriptions-item>
            <el-descriptions-item label="路线预估时间">{{oneData.estimatedTime}} 分钟</el-descriptions-item>
            <el-descriptions-item label="路线预估偏差">{{oneData.estimatedTimeDeviation}} 分钟</el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="1" v-else>
            <el-descriptions-item label="报警位置">{{oneData.adress}}</el-descriptions-item>
            <el-descriptions-item label="偏移时长">--</el-descriptions-item>
            <el-descriptions-item label="报警距离">{{oneData.warningDistance}} 米</el-descriptions-item>
            <el-descriptions-item label="报警类型" >偏移</el-descriptions-item>
        </el-descriptions>
        <span style="width:101px;display: inline-block;">查看</span><span style="color:rgba(58, 111, 195, 1);cursor: pointer;" @click="mapClick(oneData)">报警线路</span>
        <el-table height="150" v-if="oneData.alarmType == 4" :data="policeData" style="width: 100%" :default-sort = "{prop: 'date', order: 'descending'}">
          <el-table-column label="序号" type="index" :index="indexMethod">
          </el-table-column>
          <el-table-column prop="stopStartTime" label="停留开始时间" width="160">
          </el-table-column>
          <el-table-column prop="stopEndTime" label="停留结束时间" width="160">
          </el-table-column>
          <el-table-column prop="stopTime" label="停留时长" width="80">
              <template slot-scope="scope">
                <span>{{scope.row.stopTime}} 分</span>
              </template>
          </el-table-column>
          <el-table-column label="停留坐标(百度)">
              <template slot-scope="scope">
                <span>{{scope.row.lng}},{{scope.row.lat}}</span>
              </template>
          </el-table-column>
          <el-table-column label="停留位置" width="80">
            <template slot-scope="scope">
              <span style="color: #2F80ED;cursor: pointer;" @click="mapOneGPS(scope.row)">点击查看</span>
            </template>
          </el-table-column>
        </el-table>
    </el-dialog>
    <!-- 内容结束 -->
  </div>
</template>
<script>
import {BmlLushu} from 'vue-baidu-map'
import gcoord from 'gcoord'
export default {
    components: {
        BmlLushu,
    },
    data() {
        return {
          showmap:false,
          loading:false,
          current:1,              // 当前页数
          size:200,                // 显示条数
          total:0,                // 总条数
          show:false,
          purTitle: "",           // 标题
          purSearch: "",          // 搜索       
          tableData: [],          // 表格数据  
          dialogVisible: false,
          oneData:{},             // 查看单条数据
          // 地图
          markerPoint:{},
          polylinePath: [],                       // 自定义轨迹集合
          centermap:{},
          zoom:15,
          newpath:[],
          // 报警数据
          policeData:[],
          policeDas:[],
        };
    },
    created() {
        sessionStorage.removeItem('queryAll');
    },
    mounted(){
      this.purTitle = this.$route.meta.title;
      this.getpurcon();  
    },
    methods: {
      indexMethod(index) {
        return index + 1;
      },
      // 定位
      dwmap(){
        // console.log(this.zoom)
        this.zoom = 19;
        this.markerPoint = {
            lng:this.oneData.alongitude,
            lat:this.oneData.alatitude
        }
        this.centermap = {
            lng:this.oneData.alongitude,
            lat:this.oneData.alatitude
        }
        this.$forceUpdate();
      },
      // 表格数据
      getpurcon(){
        this.loading = true;
        // 传参
        var listByPageData = {
          searchStr:this.purSearch,
          page:this.current,
          size:this.size
        }
        // 渲染表格
        this.api.riskAlarm.all(listByPageData)
        .then(res=>{
          this.loading = false;
          if(res.data.code == 200){
            // console.log(res.data.data)
              this.tableData = res.data.data.dataList;
              this.total = res.data.data.totalCount;
          }
        })
      },
      // 搜索功能
      search() {
        this.tableData = [];
        this.getpurcon();
        this.current = 1;
      },
      // 查看报警列表
      getlist(){
        // 传参
        var listByPageData = {
            searchStr:this.purSearch,
            page:{
                current: this.current,
                size: this.size
            },
        }
        // 渲染表格
        this.api.ware.all(listByPageData)
        .then(res=>{
          this.loading = false;
          if(res.data.code == 200){
              this.tableData = res.data.data.records;
              this.total = res.data.data.total;
          }
        })
      },
      // 弹框点击查看单个报警点
      mapOneGPS(data){
        this.zoom = 19;
        this.centermap = {lng:data.lng,lat:data.lat};
        this.policeDas = [];
        this.policeDas.push(data);
      },
      // 查看更多报警信息
      onePurs(row){
        this.showmap = true;
        this.oneData = JSON.parse(JSON.stringify(row));
        console.log(this.oneData)
        var myGeo = new BMap.Geocoder();
        let pt = new BMap.Point(row.alongitude,row.alatitude);
        myGeo.getLocation(pt,result =>{
            this.oneData.adress = result.address;
            this.api.riskAlarm.one({id:this.oneData.id})
            .then(res=>{
              if(res.data.code == 200){
                // console.log(res.data.data)
                let AddData = res.data.data;
                let arr;
                let result = null;
                AddData.stopLogs.map(item=>{
                  if(item.stopCoordinate){
                    arr = item.stopCoordinate.split(",");
                    result = gcoord.transform([arr[0],arr[1]],gcoord.WGS84,gcoord.BD09);
                    item.lng = result[0];
                    item.lat = result[1];
                  }
                })
                this.policeData = AddData.stopLogs;
                this.policeDas = AddData.stopLogs
                // console.log(AddData.stopLogs);
                // 折现路径绘制
                let rgb = ['#3385ff','#000',"#33ff33","#c65353","#9933ff"];
                let arrpath;
                let objmap;
                for(let key in AddData.fences){
                  this.polylinePath = []
                  arrpath = AddData.fences[key].fenceContent.split(';');
                  for(let i=0;i<arrpath.length-1;i++){
                    objmap = arrpath[i].split(',');
                    this.polylinePath.push({lng:objmap[1],lat:objmap[0]})
                  }
                  this.newpath.push(
                    {
                      path:this.polylinePath,
                      rgb:rgb[key]
                    }
                  )
                }
              }
            })
            this.dialogVisible = true;
            this.markerPoint = {
                lng:this.oneData.alongitude,
                lat:this.oneData.alatitude
            }
            
            this.centermap = {
                lng:this.oneData.alongitude,
                lat:this.oneData.alatitude
            }
        });

      },
      handleClose() {
        this.showmap = false;
        this.dialogVisible = false;
        this.oneData ={};
        this.centermap = {};
        this.markerPoint = {};
        this.polylinePath = [];
        this.zoom = 15;
      },
      mapClick(data){
        // console.log(data);
        // sessionStorage.removeItem('listSearch');
        sessionStorage.setItem('queryAll',JSON.stringify(data));
        this.$router.push({name:'details'});
      },
    },
};
</script>
<style lang="less" scoped>
// 报警记录弹框样式开始
.bevconentBox{
  width: 100%;
  height: 300px;
  margin-bottom:4%;
  position: relative;
  .bmviewbox{
    width: 100%;
    height: 100%;
  }
  .bevconentbottom{
    width: 38px;
    height: 28px;
    cursor: pointer;
    // background: brown;
    text-align: center;
    position: absolute;
    bottom: 8%;
    right: 2%;
    color: #333;
    i{
      font-size: 28px;
    }
  }
}

// 报警记录弹框样式结束
.bm-view{
  width: 0px;
  height: 0px;
}
// 头部开始
.pur-top {
  width: 100%;
  height: 50px;
  padding: 9px 24px 9px 32px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  border: 1px solid rgba(102, 102, 102, 0.15);
  .pur-size {
    font-size: 16px;
    line-height: 32px;
    color: #333;
    font-weight: 600;
  }
  .pur-right {
    .el-input {
      width: 200px;
      float: right;
    }
    .el-button {
      margin-left: 16px;
      float: right;
    }
  }
}
// 头部结束
// 内容开始
.pur-nav {
  width: calc(100% - 32px);
  height: calc(100vh - 142px);
  background-color: #fff;
  margin: 8px;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  padding: 8px;
  .pur-table{
    width:100%;
    height: 94%;
  } 
  // 暂无数据样式开始
  .dataAvailable{
    width: 100%;
    height: 245px;
    position: relative;
    margin-top:185px;
    margin-bottom:279px;
    .dataAva{
      position: absolute;
      top:75%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      img{
        width: 371px;
        height: 200px;
        margin: auto;
      }
      p{
        font-size: 16px;
        line-height: 24px;
        color: #333;
      }
      .el-button{
        margin-bottom:100px;
      }
    }
  }
  // 暂无数据样式结束
  .pur-pages {
    width: 100%;
    height: 6%;
    padding: 6px 0 0 12px;
    box-sizing:border-box;
    .el-pagination {
      float: right;
    }
  }
}
// 内容结束
</style>